import React from 'react';
//import Geo from './Geo';
/*import {InstallPWA} from "../InstallPWA";
import useIsIOS from "../Hooks/useIsIOS"; pwa addon*/



var l = localStorage.getItem('lang');
    var welcome, infostart, bigscreen;
    if(l==='en'){
        welcome = "Welcome!";
        infostart = "Walk around among the museum artefacts and objects. In some places you will see a number. Enter the number in the field above to read more about the object."
        bigscreen = "This page is intended for viewing on a smaller screen. Please use your mobile phone or pad.";

    } 
    else if(l==='de'){
        welcome = "Willkommen!";
        infostart = "Schauen Sie sich bei einem Rundgang die Artefakte und Objekte des Museums an. An einigen Stellen werden Sie eine Nummer sehen. Geben Sie diese Nummer oben in das Feld ein, um mehr über das Objekt zu erfahren."
        bigscreen = "Diese Seite ist für die Betrachtung auf kleineren Bildschirmen vorgesehen. Verwenden Sie bitte Ihr Mobiltelefon oder Tablet.";
    } 
    else {
        welcome = "Välkommen!";
        infostart = "Gå runt bland föremålen i muséet. Här och var ser du en siffra. Fyll i den siffran i rutan ovan för att läsa mer om föremålet."
        bigscreen = "Den här sidan är avsedd att ses på en mindre skärm, vänligen använd din mobiletelefon eller pad.";
    }

/* const { prompt } = useIsIOS(); pwa addon likaså {prompt && <InstallPWA />} längre ner*/



const Home = () => 


    <div className="row framsida">
   
   <div className="col-md-12 messageifbigscreen">
       <div className="framsida_text">
        <h3>{bigscreen}</h3>
        <br /><a href="http://www.johannamuseet.se/" target="_top">www.johannamuseet.se &rarr; </a>
        </div>
    </div>


    <div className="col-md-12 hideifbigscreen"  text-center="true">
        <img src="/images/testbild1.jpg" style={{display:'none'}} alt="testbild" className="img img-thumbnail" />
        <div className="framsida_text" >
        <h3>{welcome}</h3>

        <p>{infostart}</p>
         </div>
         
    </div>

</div>


export default Home;