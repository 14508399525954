import React from 'react';
import { withRouter} from 'react-router-dom';
/*
const SakKort = ({sak, history}) => {


    const getSakDetalj = (imdbID) => {
        history.push('/detaljer?imdbID=' + imdbID);

    }
   const getSakDetalj = (imdbID) => {
    history.push('/detaljer?imdbID=' + imdbID);

    }

    return <div className="col-md-4">
        <div className="card" onClick={ () => getSakDetalj(sak.imdbID) }>
            <img src={sak.Poster} alt={sak.Title} className="card-img-top" />
            <div className="card-body">
                <h5 className="card-title">{sak.Title}</h5>
                <p className="card-text">{sak.Year}</p>
            </div>
        </div>
    </div>
};
*/

// nedan där tidigare imdbID har jag nu slug


const SakKort = ({sak, history}) => {


   const getSakDetalj = (slug) => {
   history.push('/detaljer?slug=' + slug);

    }
    var l = localStorage.getItem('lang'); //addon
    var rubrik;
    if(l==='en'){
        rubrik = sak.acf.rubrik_en;
    } 
    else if(l==='de'){
        rubrik = sak.acf.rubrik_de;
    } 
    else {
        rubrik = sak.acf.rubrik_se;
    }
    return <div className="col-md-4">
        <div className="card" onClick={ () => getSakDetalj(sak.slug) }>
            <img src={sak.acf.bild_1} alt={rubrik} className="card-img-top" />
            <div className="card-body">
                <h5 className="card-title">{rubrik}</h5>
            </div>
            <p>&rarr;</p>
        </div>
    </div>
};


export default withRouter(SakKort);