import React, { Component } from 'react';
import Header from './Header';
import SakLista from './SakLista';
import SakDetalj from './SakDetalj';
import Home from './Home';
import Footer from './Footer';
import Extra from './Extra';

import { BrowserRouter,Route} from 'react-router-dom';

class App extends Component {
  state = {message: 'Hello again'}


  render() {
    return (
      <BrowserRouter>
    <div>
      <Header />
      <div className="container">
        <Route path="/" exact={true} component={Home} />
        <Route path="/saker" component={SakLista} />
        <Route path="/extra" component={Extra} />
        <Route path="/detaljer" component={SakDetalj} />
      </div>
      <Footer />
    </div>
    </BrowserRouter>
    );
  }
}

export default App;
