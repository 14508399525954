import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

class Footer extends Component {


    render() {


        return (
                <div className="container">
                    <div className="row hideifbigscreen">
                        <div className="col-md-12 footer">
                        <Link to="/extra"><span className="material-icons md-36">info</span></Link>
                        </div>
                    </div>
                </div> 
          
        );
    }
}


export default withRouter(Footer);