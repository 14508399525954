import React, { Component } from 'react';
import SakKort from './SakKort'
import Axios from 'axios';
import queryString from 'query-string';

class SakLista extends Component {
    state = { saker:[] }

    componentDidMount(){
        this.fetchSaker();
        //console.log('this.props', this.props);
    }

    componentDidUpdate(prevProps){
        if(this.props.location !== prevProps.location){
            this.fetchSaker();
        }
    }
 
    fetchSaker = () => {
        let q = queryString.parse(this.props.location.search);
        Axios.get(`https://app.johannamuseet.se/wp-json/wp/v2/posts?slug=${q.searchText}`)
        //Axios.get(`https://app.johannamuseet.se/wp-json/wp/v2/posts?slug=${q.searchText}&l=${q.l}`) 
            .then(resp=>resp.data)
            //.then(data=>data.Search)
            .then(saker=>this.setState({saker}))

    }


    render() {

        //const sakLista = this.state.saker.map((m,index) => <SakKort sak={m} key={index} />)
        //const sakLista = this.state.saker.map((m,index) => <SakKort sak={m} key={m.index} />)
        const sakLista = this.state.saker.map((m,slug) => <SakKort sak={m} key={m.slug} />)
        const numRows = sakLista.length //addon
    
        return (
            <div className="row">
                <p className="addon">Number of rows = {numRows}</p>
                { sakLista }
            </div> 
            
        );
    }
}

export default SakLista;