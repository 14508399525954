import React from 'react';

const Extra = () => 


    <div className="row extra">
   
   <div className="col-md-12 hideifbigscreen">
       <div className="framsida_text">
        < br/>< br/>      
        <a href="http://www.johannamuseet.se/" target="_top"> <h3>www.johannamuseet.se &rarr;</h3> </a>
        <a href="https://www.facebook.com/JohannaMuseet-1621841061476914/" target="_top"><span class="material-icons md-36">facebook</span> </a>

        <br />
<p><b>Juni, juli och augusti:</b> Alla dagar 11-16<br />
<b>Maj och september:</b> Lör-sön 11-16<br /><br />
<b>April-oktober:</b> Gruppbesök enligt överenskommelse.<br /><br /><br />
Muséet håller stängt november-mars samt midsommarafton.<br /></p>
        </div>
    </div>



</div>

export default Extra;