import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

class Header extends Component {

    submitHandler = (evt) => {
        evt.preventDefault();
        //alert('submithandler, this is:' + this.refs.searchTf.value);

        let {history} = this.props;
        //history.push('/saker?searchText=' + this.refs.searchTf.value);//works - sends to list
        if(this.refs.searchTf.value){
        history.push('/detaljer?sak=' + this.refs.searchTf.value);//nytt test
        }
        else{ // eslint-disable-next-line
         history.push('/detaljer?sak=' + '-'); //if no number entered
        }
        
    }


    render() {


        function refreshLang(e) {
            const language = e.target.value;
            localStorage.setItem('lang', language);
            console.log(language);
            window.location.reload();
          }
          /*
          var l = localStorage.getItem('lang');
          var nummer;
            if(l==='en'){
                nummer = "Number";
            }
            else{
                nummer = "Nummer"; //samma för de o sv
                }
            */

        return (

                <div className="container darkbg">
                    <nav className="fixed-top">
                    <div className="row">
                        <div className="col-md-12 top">
                            <Link to="/"><div className="logo"></div></Link>
                            <div className="langpick">
                            <button value="se" onClick={refreshLang} className="langpickSE"> </button>
                            <button value="en" onClick={refreshLang} className="langpickEN"> </button>
                            <button value="de" onClick={refreshLang} className="langpickDE"> </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 knapprad darkbg">
                        <div className="knappradspos">
                        <form onSubmit={this.submitHandler}>
                       
                                <input type="search" maxLength="3"
                                    name="search"
                                    ref="searchTf" 
                                    placeholder="123" 
                                    className="form-control ruta hideifbigscreen" />


                                <button type="submit" className="skickaknapp hideifbigscreen">
                                </button>
                
                        </form>
                        </div>
                    </div>
                    </div>
                        
                    </nav>
                </div> 

          
        );
    }
}


export default withRouter(Header);