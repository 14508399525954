import React, { Component } from 'react';
import Axios from 'axios';
import queryString from 'query-string';
//import ReactPlayer from 'react-player';
import ReactPlayer from 'react-player/youtube';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


class SakDetalj extends Component {
    state = { sak: {} }

    componentDidMount() {
        this.fetchSaker();
        //console.log('this.props', this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.fetchSaker();
        }
    }

    fetchSaker = () => {
        let q = queryString.parse(this.props.location.search);
    Axios.get(`https://app.johannamuseet.se/wp-json/wp/v2/posts?slug=${q.sak}`)
    //Axios.get(`https://app.johannamuseet.se/wp-json/wp/v2/posts?slug=${q.slug}&l=${q.l}`)
            .then(resp => resp.data)
            .then(sak => this.setState({ sak }))

    }


    render() {
        let {sak} = this.state;
        var l = localStorage.getItem('lang');

        let output =
        <div className="text-center">
            <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            Vänta...
        </div>
        ;

        var tyvarr;
        if(l==='en'){
            tyvarr = "Unfortunately there does not seem to be an object with this number. Try a different one!";
        }
        else if(l==='de'){
            tyvarr = "Leider scheint es kein Objekt mit dieser Nummer zu geben. Versuchen Sie es mit einer anderen!";
            }
        else{
            tyvarr = "Tyvärr - det tycks inte finnas ett föremål med det här numret. Prova ett annat!";
            }

        if (sak && Object.keys(sak).length > 0) {

            var nr
            nr = sak[0].title.rendered;

            var bild1, bild2, bild3, bild4, bild5, rubrik, text1, text2, filmurl;
            bild1 = sak[0].acf.bild_1;
            bild2 = sak[0].acf.bild_2;
            bild3 = sak[0].acf.bild_3;
            bild4 = sak[0].acf.bild_4;
            bild5 = sak[0].acf.bild_5;
            //var imarray = [('https://via.placeholder.com/1080'),('https://via.placeholder.com/1080')];
            //if(!!bild1){bild1 = 'https://via.placeholder.com/1080'};
            //https://flaviocopes.com/react-how-to-loop/

            var imarray = [];

            if(!!bild1){imarray.push(bild1)}
            if(!!bild2){imarray.push(bild2)}
            if(!!bild3){imarray.push(bild3)}
            if(!!bild4){imarray.push(bild4)}
            if(!!bild5){imarray.push(bild5)}
            
            // show thumb only if more than one image:
            //var showthumbs = false //removed
            var showIndicators = false
            if(imarray.length > 1 ){
                //showthumbs = true; //removed
                showIndicators = true;
            }

            if(l==='en'){
                if(sak[0].acf.rubrik_en.length > 1){
                rubrik = sak[0].acf.rubrik_en;
                text1 = sak[0].acf.text1_en;
                text2 = sak[0].acf.text2_en;
                filmurl = sak[0].acf.film_url_en;
                }
                else{
                    rubrik = sak[0].acf.rubrik_se;
                    text1 = sak[0].acf.text1_se;
                    text2 = sak[0].acf.text2_se;
                    filmurl = sak[0].acf.film_url_se;
                }
            }
            else if(l==='de'){
                if(sak[0].acf.rubrik_de.length > 1){
                    rubrik = sak[0].acf.rubrik_de;
                    text1 = sak[0].acf.text1_de;
                    text2 = sak[0].acf.text2_de;
                    filmurl = sak[0].acf.film_url_de;
                    }
                    else{
                        rubrik = sak[0].acf.rubrik_se;
                        text1 = sak[0].acf.text1_se;
                        text2 = sak[0].acf.text2_se;
                        filmurl = sak[0].acf.film_url_se;
                    }
                }
            else{
                rubrik = sak[0].acf.rubrik_se;
                text1 = sak[0].acf.text1_se;
                text2 = sak[0].acf.text2_se;
                filmurl = sak[0].acf.film_url_se;
                }



            output =
            <div>
            <div className="row hideifbigscreen">
                    
                    <div className="col-md-12">
                    <Carousel 
                    infiniteLoop 
                    useKeyboardArrows 
                    autoPlay 
                    showThumbs={false}
                    showIndicators={showIndicators}
                    >
                   
                {imarray.map(function(imgSrc) {
                return (
                <div key={ imgSrc }><img src={ imgSrc } alt={rubrik} /></div>
                );
                })}

                    </Carousel>
                    </div>
                </div>

                <div className="row hideifbigscreen">

                    <div className="col-md-12 infochunk"> 
                        <div className="textDiv">
                        <p className="smallp">(#{nr})</p>
                        {!!bild1 &&  <img src={bild1} alt={rubrik} className="img img-thumbnail hide" />}
                        {!!rubrik && <h3>{rubrik}</h3> }
                        {!!text1 && <p>{text1}</p> }
                        {!!bild2 && <img src={bild2} alt={rubrik} className="img img-thumbnail hide" />}
                        {!!text2 && <p>{text2}</p> }
                        </div>
                        {!!filmurl && <div className="player-wrapper">
                            <ReactPlayer className="react-player"
                            width='100%'
                            height='100%'
                            url={filmurl} 
                            playing={false}
                            controls={true}
                            onPlay='playVideo'
                            onEnded='stopVideo'
                            onPause='pauseVideo'
                            allowfullscreen={true}
                            //onPlay={() => screenfull.request()}
                            
                            />
                            <button onClick={this.handleClickFullscreen}>Fullscreen</button>
                            </div>
                        }
                     </div>

            </div> </div>;
        }
        else{
            output =
            <div className="row tyvarr">
                <div className="spinner-border text-danger" role="status"></div>
                <p>{tyvarr}</p>
            </div>
        }

        return output;
    }
}

export default SakDetalj;